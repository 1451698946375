/* This file is a template file for the URLs. It is used to generate a config.js file with the help 
of the workflow file during deployment. The links are stored as secrets in GitHub. */

export const OKTA_URL = 'https://spark-d.redbull.com';
export const OKTA_WORKFLOW_REQUEST_URL = 'https://func-b2b-spark-dev001.azurewebsites.net/api/func-httptrigger-create-request?code=CMdHFYuYV4oje-mWZ9XWusSAU497xEmgAYUQfh2P7VB3AzFujF9r7w==';
export const OKTA_SUCCESS_URL = 'https://spark-d.redbull.com';
export const OKTA_HELP_URL = 'https://spark-d.redbull.com/help/login';
export const AZURE_MAPS_IP_LOCALISATION = 'https://func-b2b-spark-iplocalisation.azurewebsites.net/api/userLocalisationOverIp?code=dTq9MXY9xLvYSP7T8jCyJiR1WjnKlSNAGV6Q_6f9NB7vAzFunFgNnw=='
export const GET_GROUP_API = "https://func-b2b-spark-dev001.azurewebsites.net/api/selfReg-getGroupsByCountry?code=6JpafmwuNDSdfHSp6Qln-JSB6L-n26eGicO_SMKx2rBeAzFuUB50xw==";
export const CREATE_USER_API = "https://func-b2b-spark-dev001.azurewebsites.net/api/selfReg-createUser?code=ankeCVuTEIfGb2EmjPcbDXUELbrfZVbcL9kwTZpmF2V4AzFuqTgCmg==";
